/* eslint-disable max-len */
import { useState } from 'react';
import { AvatarGroup, Template } from '@components';
import {
    Box,
    CircularProgress,
    Container,
    ListItemIcon,
    MenuItem,
    MenuList,
    Typography,
    useTheme,
} from '@mui/material';
import { main } from '@static/menus';
import { files, rem } from '@utils';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@hooks/user';
import { useAuth } from '@hooks/auth';

const UserMenu: React.FC = () => {
    const { typography, palette } = useTheme();
    const navigate = useNavigate();
    const user = useUser();
    const auth = useAuth();

    const [logoutLoading, setLogoutLoading] = useState(false);

    const logoutUser = async () => {
        setLogoutLoading(true);
        const response = await auth.logout({ user });
        if (!response?.error) {
            setLogoutLoading(false);
            navigate('/');
            window.location.reload();
        }
    };

    return (
        <Box>
            <Container maxWidth='lg'>
                <Box mb={rem(10)}>
                    <AvatarGroup
                        borderWidth='none'
                        align={'right'}
                        size={'medium'}
                        title={user.info.data.name}
                        legend={`Saldo: ${user.info.data.availableBalance}`}
                        src={user.info.data.images ?
                            files.load(
                                user.info.data.images.avatar,
                                'server',
                            ) : ''} />
                </Box>

                <MenuList>
                    {main.map(({ id, title, href, icon }, i) => (
                        <MenuItem
                            key={id}
                            onClick={() => navigate(href)}
                            disableGutters
                            sx={{
                                pt: rem(5),
                                pb: rem(5),
                            }}>
                            <ListItemIcon>
                                <I
                                    icon={icon}
                                    fontSize={rem(16)}
                                    color={palette.darkGray}
                                />
                            </ListItemIcon>
                            <Typography
                                variant="inherit"
                                fontSize={rem(13)}
                                color="darkGray"
                                fontWeight={typography.fontWeightSemiBold}>
                                {title}
                            </Typography>
                        </MenuItem>
                    ))}
                    <Box sx={{ borderBottom: `${rem(1)} solid ${palette.cityLights}` }}></Box>
                    <Box mt={1.4}>
                        <Typography
                            sx={{
                                fontWeight: typography.fontWeightBold,
                                fontSize: '0.8125rem',
                                color: palette.darkGray,
                                mb: 1,
                            }}
                            variant='h4'>
                            Serviços
                        </Typography>
                        <MenuItem
                            onClick={() => {
                                navigate('/servicos/recargas');
                            }}
                            disableGutters>
                            <ListItemIcon>
                                <I
                                    icon={['far', 'credit-card']}
                                    fontSize={rem(16)}
                                    color={palette.darkGray}
                                />
                            </ListItemIcon>
                            <Typography
                                variant="inherit"
                                fontSize={rem(13)}
                                color="darkGray"
                                fontWeight={typography.fontWeightSemiBold}>
                                Recarga de Cartão
                            </Typography>
                        </MenuItem>
                    </Box>
                    <Box sx={{ borderBottom: `${rem(1)} solid ${palette.cityLights}` }}></Box>
                    <MenuItem
                        onClick={() => {
                            logoutUser();
                        }}
                        disableGutters>
                        {logoutLoading ?
                            <Box px={2}>
                                <CircularProgress
                                    size={12}
                                    sx={{
                                        color:
                                            palette.
                                                darkGray,
                                    }} />
                            </Box> :
                            <>
                                <ListItemIcon>
                                    <I
                                        icon={['far', 'right-to-bracket']}
                                        fontSize={rem(16)}
                                        color={palette.darkGray}
                                    />
                                </ListItemIcon>
                                <Typography
                                    variant="inherit"
                                    fontSize={rem(13)}
                                    fontWeight={typography.fontWeightSemiBold}>
                                    Sair
                                </Typography>
                            </>
                        }
                    </MenuItem>
                </MenuList>

                <Box mt={rem(18)}>
                    <Typography
                        variant="body2"
                        component='h1'
                        align='left'
                        fontSize={rem(14)}
                        color='darkGray'
                        whiteSpace='nowrap'
                        fontWeight={typography.fontWeightSemiBold}
                        gutterBottom>
                        Parceiros
                    </Typography>
                    <Box py={2}>
                        <Template.Partners />
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export { UserMenu };
