import { Box, Stack, Typography } from '@mui/material';
import { sx } from '../../sx';

interface RechargeListValueProps {
    title: string;
    value: string;
    divider?: boolean;
    boldTitle?: boolean;
}

export const RechargeListValue = ({
    title,
    value,
    boldTitle,
    divider = true,
}: RechargeListValueProps) => {
    const { css } = sx();
    return (
        <Box>
            <Stack py={1} direction="row" justifyContent="space-between">
                <Typography
                    color="dark"
                    variant="subtitle2"
                    fontWeight={boldTitle ? 'bold' : 'medium'}
                    textAlign="left">
                    {title}
                </Typography>
                <Typography
                    variant="subtitle2"
                    textAlign="left"
                    fontWeight="bold"
                    color="dark">
                    {value}
                </Typography>
            </Stack>
            {divider && (
                <Box sx={{ ...css.divider }} />
            )}
        </Box>
    );
};
