import { Box, Container, Divider, Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { skeletonSx } from './skeleton-sx';
import { styles } from '@utils';

export const skeleton = () => {
    const { css } = skeletonSx();
    const mediaQuery = styles.mediaQueryCSS();
    const sideMenu = (
        <Box sx={css.sideMenu.wrapper}>
            <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                mb={1}>
                <Box sx={css.sideMenu.content.wrapper}>
                    <Skeleton variant="rounded" width={24} height={24} />
                    <Skeleton
                        variant="text"
                        width='100%'
                        sx={css.sideMenu.content.text}
                    />
                </Box>
            </Stack>
            <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                mb={1}>
                <Box sx={css.sideMenu.content.wrapper}>
                    <Skeleton variant="rounded" width={24} height={24} />
                    <Skeleton
                        variant="text"
                        width='100%'
                        sx={css.sideMenu.content.text}
                    />
                </Box>
            </Stack>
            <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                mb={1}>
                <Box sx={css.sideMenu.content.wrapper}>
                    <Skeleton variant="rounded" width={24} height={24} />
                    <Skeleton
                        variant="text"
                        width='100%'
                        sx={css.sideMenu.content.text}
                    />
                </Box>
            </Stack>
        </Box>
    );
    const content = (
        <Stack mb={1} p={!mediaQuery.sm ? 3 : 0}>
            <Box sx={css.sideMenu.content.wrapper}>
                <Skeleton variant="rounded" width={24} height={24} />
                <Skeleton
                    variant="text"
                    width={160}
                    sx={css.sideMenu.content.text}
                />
            </Box>
            <Box mt={2}>
                <Skeleton variant="rounded" width='100%' height={260} />
            </Box>
        </Stack>
    );
    const headerDefault = (
        <Box>
            <Box sx={{ ...css.headerDefault.box.wrapperHeader }}>
                <Container>
                    <Box sx={{ ...css.headerDefault.box.wrapperItens }}>
                        <Box sx={{ ...css.headerDefault.box.logo }}>
                            <Skeleton variant="rectangular" width={135} height={32} />
                        </Box>
                        <Box sx={{ ...css.headerDefault.box.wrapperButtons }}>
                            <Box sx={{ ...css.headerDefault.box.buttons }}>
                                <Skeleton variant="rounded" width={'100%'} height={48} />
                            </Box>
                            <Stack
                                sx={{ ...css.headerDefault.stack.texts }}
                                spacing={1} direction='row' alignItems='center'>
                                <Skeleton variant="circular" width={24} height={24} />
                                <Stack spacing={0.3}>
                                    <Skeleton variant="text" width={102} height={18} />
                                    <Skeleton variant="text" width={102} height={18} />
                                </Stack>
                            </Stack>
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Box sx={{ ...css.headerDefault.box.wrapperSub }}>
                <Container>
                    <Box sx={{ ...css.headerDefault.box.wrapperItensSub }}>
                        <Stack pl={1} direction={'row'} alignItems={'center'} spacing={2}>
                            <Skeleton variant="rounded" width={105} height={21} />
                            <Skeleton variant="rounded" width={105} height={21} />
                            <Skeleton variant="rounded" width={125} height={21} />
                            <Skeleton variant="rounded" width={112} height={21} />
                        </Stack>
                        <Stack alignItems={'center'} direction={'row'} spacing={3}>
                            <Skeleton variant="circular" width={38} height={38} />
                            <Skeleton variant="circular" width={38} height={38} />
                        </Stack>
                    </Box>
                </Container>
            </Box>
        </Box>
    );

    const headerMobile = (
        <Box>
            <Box sx={{ ...css.headerMobile.box.wrapperHeader }}>
                <Container sx={{ height: '100%' }}>
                    <Grid container spacing={0} height='100%'>
                        <Grid item xs={2}>
                            <Box sx={{ ...css.headerMobile.box.iconMenu }}>
                                <Skeleton variant="rectangular" width={20} height={20} />
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Box sx={{ ...css.headerMobile.box.logo }}>
                                <Skeleton variant="rectangular" width={118} height={28} />
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Stack
                                direction='row'
                                alignItems='center'
                                justifyContent='flex-end'
                                height='100%'
                                spacing={2}>
                                <Skeleton variant="circular" width={30} height={30} />
                                <Skeleton variant="circular" width={30} height={30} />
                                <Skeleton variant="circular" width={30} height={30} />
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box sx={{ ...css.headerMobile.box.wrapperSub }}>
                <Container>
                    <Box sx={{ ...css.headerMobile.box.wrapperItensSub }}>
                        <Stack
                            sx={{ ...css.headerMobile.stack.sub }}
                            direction={'row'} alignItems={'center'}
                            justifyContent={'space-between'}>
                            <Skeleton variant="rounded" width={87} height={21} />
                            <Divider orientation="vertical" flexItem />
                            <Skeleton variant="rounded" width={106} height={21} />
                            <Divider orientation="vertical" flexItem />
                            <Skeleton variant="rounded" width={94} height={21} />
                        </Stack>
                    </Box>
                </Container>
            </Box>
        </Box>
    );

    return {
        headerDefault,
        headerMobile,
        sideMenu,
        content,
    };
};

