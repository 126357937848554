import { client } from '@api';
import {
    PurchaseCreditCardProps,
    SendRechargeCodeEmailProps,
} from '@interfaces/Services';

export const loadRechargeValues = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/rechargeCard');

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const sendRechargeCodeEmail = async (data: SendRechargeCodeEmailProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.post(`/sendEmail/whereToSend/${data.sendTo}`);

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};


export const purchaseCreditCard = async (data: PurchaseCreditCardProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.post('/purchase/creditCard', data);

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};


