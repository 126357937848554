import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Button, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

interface SendCodeTypeProps {
    type: 'email' | 'sms';
    info: string;
    disabled?: boolean;
    isLoading?: boolean;
    onClick: () => void;
}

const sendCodeTypeData = {
    email: {
        label: 'Email',
        icon: ['fal', 'envelope'] as IconProp,
    },
    sms: {
        label: 'SMS',
        icon: ['fal', 'message'] as IconProp,
    },
};

export const SendCodeType:React.FC<SendCodeTypeProps> = ({
    type, info, disabled, isLoading, onClick,
}: SendCodeTypeProps) => {
    const { palette } = useTheme();
    return (
        <Button disabled={disabled} onClick={onClick} sx={{
            height: '4.375rem',
            width: '100%',
            border: `0.0625rem solid ${palette.cityLights} !important`,
            px: 2,
            borderRadius: '0.25rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        }}>
            <Stack direction={'row'} alignItems={'center'} spacing={'20px'}>
                <I fontSize={26} icon={sendCodeTypeData[type].icon} />
                <Stack>
                    <Typography
                        fontSize={'0.875rem'}
                        textAlign="left"
                        fontWeight="bold"
                        variant='caption'>
                        {sendCodeTypeData[type].label}
                    </Typography>
                    <Typography
                        fontSize={'0.875rem'}
                        textAlign="left"
                        fontWeight="medium"
                        variant='body1'>
                        {info}
                    </Typography>
                </Stack>
            </Stack>
            {!isLoading ?
                <I fontSize={16} icon={['far', 'chevron-right']} /> :
                <CircularProgress size={16} color='inherit' />
            }
        </Button>
    );
};
