/* eslint-disable max-len */
import { Box, Button, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import McLogo from '@img/mc-symbol.png';
import { ExpandedListValues, Image } from '@components';
import { RechargeListValue } from '../../list-value';
import { useUser } from '@hooks/user';
import $ from 'jquery';
import 'jquery-mask-plugin';
import React from 'react';
import { money, validateBirthDate } from '@utils';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { sx } from '../../../sx';

interface RechargeStep3FormProps {
    ccnumber: string;
    birthDate: string;
}

interface RechargeStep3Props {
    onSubmitButton: () => void
    handlePrevious: () => void
    visible: boolean
}

export const RechargeStep3 = ({
    onSubmitButton,
    handlePrevious,
    visible,
}: RechargeStep3Props) => {
    const { css } = sx();
    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm<RechargeStep3FormProps>({
        mode: 'onSubmit',
    });
    const { recharge } = useUser();

    const onSubmit: SubmitHandler<RechargeStep3FormProps> = async (data) => {
        if (!validateBirthDate(data.birthDate)) {
            setError('birthDate', { message: 'Data inválida' });
            return;
        }
        recharge.purchase.handleObj('codeCard', data.ccnumber);
        recharge.purchase.handleObj('birthDate', data.birthDate.split('/').reverse().join('-'));
        onSubmitButton();
    };

    const inputMasks = () => {
        $('.ccnumber').mask('ZZZZ ZZZZ ZZZZ ZZZZ', {
            translation: {
                'Z': {
                    pattern: /[0-9*]/,
                },
            },
        });
        $('.birthDate').mask('00/00/0000');
    };

    React.useEffect(() => {
        inputMasks();
    }, []);

    return (
        <Box p={4} display={visible ? 'block' : 'none'}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Box mb={3}>
                    <Typography
                        color="dark"
                        variant="h6"
                        textAlign="left"
                        fontWeight="bold"
                        gutterBottom>
                        Dados do cartão
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        textAlign="left"
                        color="dark">
                        Digite as informações do seu cartão abaixo.
                    </Typography>
                </Box>
                <Stack width="100%" mb={3}>
                    <TextField
                        fullWidth
                        focused
                        label='Número do cartão'
                        id="outlined-basic"
                        helperText=''
                        variant="outlined"
                        size="small"
                        error={!!errors.ccnumber}
                        {...register('ccnumber',
                            { required: true, minLength: 19 },
                        )}
                        inputProps={{
                            className: 'ccnumber',
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Image
                                        src={McLogo}
                                        alt="Mastercard"
                                        maxHeight={20}
                                        objectFit='contain'
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Stack>
                <Box mb={3}>
                    <Typography
                        color="dark"
                        variant="h6"
                        textAlign="left"
                        fontWeight="bold"
                        gutterBottom>
                        Dados do titular do cartão
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        textAlign="left"
                        color="dark">
                        Digite as informações do titular do cartão abaixo.
                    </Typography>
                </Box>
                <Stack width="100%" mb={3}>
                    <TextField
                        fullWidth
                        focused
                        label='Data de nascimento'
                        id="outlined-basic"
                        helperText=''
                        variant="outlined"
                        size="small"
                        placeholder='DD/MM/AAAA'
                        error={!!errors.birthDate}
                        {...register('birthDate',
                            { required: true },
                        )}
                        inputProps={{
                            onChange: () => {
                                clearErrors('birthDate');
                            },
                            className: 'birthDate',
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Stack width={27} direction={'row'} justifyContent={'center'}>
                                        <I icon={['fas', 'calendar-days']} />
                                    </Stack>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Stack>
                <Box>
                    <Box mb={1}>
                        <Typography
                            color="dark"
                            variant="body1"
                            textAlign="left"
                            fontWeight="bold">
                            Resumo do resgate
                        </Typography>
                    </Box>
                    <RechargeListValue
                        title='Crédito'
                        value={!!recharge.values.selectedValue.sku ? 'Presente Perfeito' : '-'}
                        divider
                    />
                    <RechargeListValue
                        title='Valor em créditos'
                        value={!!recharge.values.selectedValue.sku ? `R$${money.formatNumberToPriceBRL(recharge.values.selectedValue.value)}` : '-'}
                        divider
                    />
                    <RechargeListValue
                        title='Valor em pontos'
                        value={!!recharge.values.selectedValue.sku ? `${recharge.values.selectedValue.pointValue} Pontos` : '-'}
                        divider
                    />
                    <Box py={1}>
                        <ExpandedListValues
                            mainValue={{
                                title: 'Taxas',
                                value:
                                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                        <Typography sx={{ ...css.texts.expandedMainValueChildren }}>
                                            {!!recharge.values.selectedValue.sku ? `${recharge.values.selectedValue.fee} Pontos` : '-'}
                                        </Typography>
                                    </Stack>,

                            }}
                            expandedChildren={ !!recharge.values.selectedValue.sku ?
                                <Stack spacing={1} ml={'1.25rem'}>
                                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Typography sx={{ ...css.texts.expandedValueText }}> Administrativa</Typography>
                                        <Typography sx={{ ...css.texts.expandedValueChildren }}>
                                            {recharge.values.selectedValue.fee} Pontos
                                        </Typography>
                                    </Stack>
                                </Stack> : undefined
                            }
                        ></ExpandedListValues>
                    </Box>
                    <Box sx={{ ...css.divider }} />
                    <RechargeListValue
                        title='Total'
                        boldTitle
                        value={!!recharge.values.selectedValue.sku ? `${recharge.values.selectedValue.pointValue+recharge.values.selectedValue.fee} Pontos` : '-'}
                        divider
                    />
                    <Stack direction="row" justifyContent="flex-end" spacing={2} pt={4}>
                        <Button
                            type='button'
                            variant='text'
                            size='large'
                            onClick={handlePrevious}>
                            Voltar
                        </Button>
                        <Button
                            type='submit'
                            variant='contained'
                            size='large'>
                            Fazer Resgate
                        </Button>
                    </Stack>
                </Box>
            </Box>
        </Box>
    );
};
