/* eslint-disable max-len */
import {
    Box,
    Button,
    Container,
    Divider,
    Skeleton,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ExpandedListValues, MessageBox, Template } from '@components';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';


import Cpp from '@img/cpp.png';
import { RechargeHero } from './components/hero';
import { RechargeScreenStep1 } from './components/screen-steps/step-1';
import UseAutocomplete, { Label } from './components/autocomplete';
import { RechargeListValue } from './components/list-value';
import React from 'react';
import { money, styles } from '@utils';
import { ToggleStaticProps } from '@interfaces/index';
import { RechargeStep1 } from './components/recharge-steps/step-1';
import { RechargeStep2 } from './components/recharge-steps/step-2';
import { RechargeStep3 } from './components/recharge-steps/step-3';
import { RechargeStep4 } from './components/recharge-steps/step-4';
import { RechargeStep5 } from './components/recharge-steps/step-5';
import { useStepper } from '@hooks/stepper';
import { useUser } from '@hooks/user';
import { useNavigate } from 'react-router-dom';
import { sx } from './sx';


interface MuiContainerProps {
    active: boolean;
    children?: React.ReactNode;
}

const MuiContainer = ({ active, children }: MuiContainerProps) => (
    active ? (
        <Box>
            {children}
        </Box>
    ) : (
        <Container maxWidth="lg">
            {children}
        </Container>
    )
);

export const Recharge = () => {
    const rechargeDialog = React.useRef<ToggleStaticProps>(null);

    const navigate = useNavigate();
    const { palette } = useTheme();
    const stepper = useStepper({ steps: ['step-1', 'step-2', 'step-3', 'step-4', 'step-5'] });
    const stepperScreens = useStepper({ steps: ['screen-1', 'screen-2'] });
    const matches = useMediaQuery('(min-width:600px)');
    const { recharge } = useUser();

    const { css } = sx();
    const mediaQuery = styles.mediaQueryCSS();

    // const closeRechargeDialog = () => {
    //     rechargeDialog.current?.hide();
    // };

    const handleCompleteSteps = () => {
        rechargeDialog.current?.hide();
        setTimeout(() => {
            stepper.completedSteps();
            stepper.allStepsCompleted();
            stepper.goToStep(0);
        }, 100);
    };

    React.useEffect(() => {
        if ((stepperScreens.activeStep === 1 && !recharge.values.list.isLoaded) ||
            recharge.values.list.status === 403) {
            recharge.values.load();
        }
    }, [stepperScreens.activeStep]);

    React.useEffect(() => {
        recharge.clearData();
    }, []);

    return (
        <>
            {stepperScreens.activeStep === 0 && (
                <Container
                    sx={{
                        mt: matches ? 2 : 0,
                    }}
                    maxWidth="lg">
                    <RechargeScreenStep1 handleNext={stepperScreens.handleNext} />
                </Container>
            )}
            {stepperScreens.activeStep === 1 && (
                <Box pt={matches ? 3 : 0} pb={matches ? 8 : 4}>
                    <MuiContainer active={!matches}>
                        <RechargeHero
                            title='Recarregue o Seu Cartão Aqui'
                            description='Se você tem um cartão pré-pago emitido, realize a recarga.'
                            image={Cpp}
                            paddingBottom={!matches ? 32 : 6}
                        />
                    </MuiContainer>
                    <Box mt={!matches ? '-231px' : 3}>
                        <Container maxWidth="lg">
                            <Box pt={3} p={matches ? 0 : 4} boxShadow={matches ? 0 : 4} sx={{
                                backgroundColor: palette.common.white,
                            }}>
                                <Grid container spacing={matches ? 6 : 2}>
                                    <Grid size={matches ? 8 : 12}>
                                        {!recharge.values.list.isLoaded ?
                                            <Skeleton variant='rounded' height={matches ? 140 : 300} /> :
                                            recharge.values.list.status === 403 ?
                                                <Box>
                                                    <MessageBox
                                                        type={'info'}
                                                        message='Faça o login para recarregar seu cartão'
                                                    />
                                                    <Stack
                                                        direction='row'
                                                        spacing={2}
                                                        justifyContent='center'>
                                                        <Button
                                                            type='submit'
                                                            variant='contained'
                                                            size='large'
                                                            onClick={() => navigate('/auth/login', {
                                                                state: {
                                                                    backHistory: window.location.pathname,
                                                                },
                                                            })}>
                                                            Fazer login
                                                        </Button>
                                                    </Stack>
                                                </Box> :
                                                <>
                                                    <Box boxShadow={matches ? 4 : 0} borderRadius={1} p={matches ? 4 : 0}>
                                                        <Stack
                                                            direction={matches ? 'row' : 'column'}
                                                            alignItems={matches ? 'flex-end' : 'center'}
                                                            width="100%"
                                                            spacing={matches ? 2 : 0}>
                                                            <UseAutocomplete />
                                                            {!!recharge.values.selectedValue.sku &&
                                                            <>
                                                                <Box
                                                                    height={56}
                                                                    width={56}
                                                                    minWidth={56}
                                                                    display="flex"
                                                                    alignItems="center"
                                                                    justifyContent="center"
                                                                >
                                                                    <I icon={['fas', matches ? 'arrow-right' : 'arrow-down']} color={palette.primaryColor} />
                                                                </Box>
                                                                <Box width="100%">
                                                                    <Label>Valor em pontos</Label>
                                                                    <Stack
                                                                        direction="row"
                                                                        alignItems="center"
                                                                        spacing={0.3}
                                                                        height={56}
                                                                        width="100%"
                                                                        sx={{
                                                                            backgroundColor: palette.snowWhite,
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            variant="subtitle1"
                                                                            color="dark"
                                                                            fontWeight="bold"
                                                                            pl={2}
                                                                        >
                                                                            {recharge.values.selectedValue.pointValue}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="subtitle2"
                                                                            color="dark"
                                                                            fontWeight="medium"
                                                                        >
                                                                            Pontos
                                                                        </Typography>
                                                                    </Stack>
                                                                </Box>
                                                            </>
                                                            }
                                                        </Stack>
                                                    </Box>
                                                    {matches && (
                                                        <Box>
                                                            <Box pt={4} pb={3}>
                                                                <Divider />
                                                            </Box>
                                                            <Box>
                                                                <Typography
                                                                    color="dark"
                                                                    variant={matches ? 'h6' : 'subtitle1'}
                                                                    textAlign="left"
                                                                    fontWeight="bold"
                                                                    gutterBottom>
                                                                    Descrição
                                                                </Typography>
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    textAlign="left"
                                                                    color="dark">
                                                                    O Presente Perfeito é exclusivo para compras na função crédito. Não permite saques, nem transferências.
                                                                    O Presente Perfeito conta com uma ampla rede credenciada, presente em todo o território nacional.
                                                                    Validade dos créditos 12 meses.
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    )}
                                                </>
                                        }
                                    </Grid>
                                    <Grid size={matches ? 4 : 12}>
                                        {!recharge.values.list.isLoaded ?
                                            <Box>
                                                <Stack spacing={1}>
                                                    <Skeleton variant='text' />
                                                    <Skeleton variant='rounded' height={40} />
                                                    <Skeleton variant='rounded' height={40} />
                                                    <Skeleton variant='rounded' height={40} />
                                                    <Skeleton variant='rounded' height={40} />
                                                </Stack>
                                            </Box> :
                                            <>
                                                <Box mb={1}>
                                                    <Typography
                                                        color="dark"
                                                        variant="body1"
                                                        textAlign="left"
                                                        fontWeight="bold">
                                                        Resumo do resgate
                                                    </Typography>
                                                </Box>
                                                <RechargeListValue
                                                    title='Crédito'
                                                    value={!!recharge.values.selectedValue.sku ? 'Presente Perfeito' : '-'}
                                                    divider
                                                />
                                                <RechargeListValue
                                                    title='Valor em créditos'
                                                    value={!!recharge.values.selectedValue.sku ? `R$${money.formatNumberToPriceBRL(recharge.values.selectedValue.value)}` : '-'}
                                                    divider
                                                />
                                                <RechargeListValue
                                                    title='Valor em pontos'
                                                    value={!!recharge.values.selectedValue.sku ? `${recharge.values.selectedValue.pointValue} Pontos` : '-'}
                                                    divider
                                                />
                                                <Box py={1}>
                                                    <ExpandedListValues
                                                        mainValue={{
                                                            title: 'Taxas',
                                                            value:
                                                                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                                                    <Typography sx={{ ...css.texts.expandedMainValueChildren }}>
                                                                        {!!recharge.values.selectedValue.sku ? `${recharge.values.selectedValue.fee} Pontos` : '-'}
                                                                    </Typography>
                                                                </Stack>,

                                                        }}
                                                        expandedChildren={ !!recharge.values.selectedValue.sku ?
                                                            <Stack spacing={1} ml={'1.25rem'}>
                                                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                                    <Typography sx={{ ...css.texts.expandedValueText }}> Administrativa</Typography>
                                                                    <Typography sx={{ ...css.texts.expandedValueChildren }}>
                                                                        {recharge.values.selectedValue.fee} Pontos
                                                                    </Typography>
                                                                </Stack>
                                                            </Stack> : undefined
                                                        }
                                                    ></ExpandedListValues>
                                                </Box>
                                                <Box sx={{ ...css.divider }} />
                                                <RechargeListValue
                                                    title='Total'
                                                    boldTitle
                                                    value={!!recharge.values.selectedValue.sku ? `${recharge.values.selectedValue.pointValue+recharge.values.selectedValue.fee} Pontos` : '-'}
                                                    divider
                                                />
                                                {/* <RechargeListValue
                                                    title='Valor em pontos'
                                                    value={!!recharge.values.selectedValue.sku ? `${recharge.values.selectedValue.pointValue} Pontos` : '-'}
                                                    divider
                                                /> */}
                                                {!!recharge.purchase.list.value &&
                                                    <Box display="flex" justifyContent="flex-end" py={2} pb={0}>
                                                        <Button
                                                            fullWidth
                                                            type='button'
                                                            variant='contained'
                                                            size='large'
                                                            onClick={() => rechargeDialog.current?.show()}>
                                                            Fazer Resgate
                                                        </Button>
                                                    </Box>
                                                }
                                            </>
                                        }
                                    </Grid>
                                </Grid>
                            </Box>

                            {!matches && (
                                <Box pt={4} pb={3}>
                                    <Box>
                                        <Typography
                                            color="dark"
                                            variant={matches ? 'h6' : 'subtitle1'}
                                            textAlign="left"
                                            fontWeight="bold"
                                            gutterBottom>
                                            Descrição
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            textAlign="left"
                                            color="dark">
                                            O Presente Perfeito é exclusivo para compras na função crédito. Não permite saques, nem transferências.
                                            O Presente Perfeito conta com uma ampla rede credenciada, presente em todo o território nacional.
                                            Validade dos créditos 12 meses.
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                        </Container >
                    </Box>
                </Box>
            )}
            <Template.MuiDialog
                ref={rechargeDialog}
                maxWidth='sm'
                fullScreen={!mediaQuery.sm}>
                <RechargeStep1
                    visible={stepper.activeStep === 0}
                    onSubmitButton={() => stepper.goToStep(1)}
                    handlePrevious={handleCompleteSteps}
                />
                <RechargeStep2
                    visible={stepper.activeStep === 1}
                    onSubmitButton={() => stepper.goToStep(2)}
                    handlePrevious={() => stepper.goToStep(0)}
                />
                <RechargeStep3
                    visible={stepper.activeStep === 2}
                    handlePrevious={() => stepper.goToStep(1)}
                    onSubmitButton={() => stepper.goToStep(3)}
                />
                <RechargeStep4
                    visible={stepper.activeStep === 3}
                    handlePrevious={() => stepper.goToStep(2)}
                    onSubmitButton={() => stepper.goToStep(4)}
                />
                <RechargeStep5
                    visible={stepper.activeStep === 4}
                    onSubmitButton={handleCompleteSteps}
                />
            </Template.MuiDialog>
        </>
    );
};
