export const validateBirthDate = (date: string) => {
    const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;

    if (!regex.test(date)) {
        return false;
    }

    const [day, month, year] = date.split('/').map(Number);

    const convertDate = new Date(year, month - 1, day);

    if (
        convertDate.getFullYear() !== year ||
        convertDate.getMonth() + 1 !== month ||
        convertDate.getDate() !== day
    ) {
        return false;
    }

    // Valida se a data não está no futuro
    const today = new Date();
    const limitDate = new Date();
    limitDate.setFullYear(today.getFullYear() - 16);

    // Valida se a data de nascimento é anterior ou igual à data limite
    return convertDate <= limitDate;

    return true;
};
