/* eslint-disable indent */
/* eslint-disable max-len */
import React from 'react';
import { AddressBox, ExpandedListValues, HeaderPage, ProductInline, Template, AvatarGroup, MessageBox, Feedback, TitleSectionShopping } from '@components';
import { Box, Container, Grid, Stack, Button, Typography, Skeleton, AlertColor, CircularProgress } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { sx } from './sx';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { files, formatAddress, formatShortDate, money, styles } from '@utils';
import { ToggleStaticProps } from '@interfaces';
import { useUser } from '@hooks/user';
import { paymentMethodLabel, paymentMethods } from '@static/payment-methods';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { PaymentMethodType } from '@types';
import { Card, Coupon, Wallet } from './payment-methods';
import { useGlobal } from '@hooks/global';
import { useLayout } from '@hooks/layout';
import { RadioPoints } from './components/radio-points';
import { PaymentFormButton } from './components/payment-form-button';
import { CartDataProps, StoreItemCartProps } from '@interfaces/Services';
import { useLoad } from '@hooks/load';

const Checkout: React.FC = () => {
    const { css } = sx();
    const mediaQuery = styles.mediaQueryCSS();
    const user = useUser();
    const { servicesLoad } = useLoad();
    const global = useGlobal();
    const navigate = useNavigate();
    const { layout } = useLayout();
    const [searchParams] = useSearchParams();
    const applicationType = global.app.view.application.type;

    const dialogEditAddress = React.useRef<ToggleStaticProps>(null);

    const [openSnack, setOpenSnack] = React.useState(false);
    const [responseData, setResponseData] = React.useState({
        isLoaded: false,
        type: 'success' as AlertColor,
        message: '',
    });

    const [itemsDiscounts, setItemsDiscounts] = React.useState([] as StoreItemCartProps[]);

    const checkItemDiscountItems = (cartData: CartDataProps) => {
        if (cartData.stores) {
            cartData.stores.map((store) => {
                const findedItems = store.items.filter((item) => item.itemDiscount?.sku);
                setItemsDiscounts(findedItems);
            });
        }
    };

    const findAddress = () => {
        const addressId = searchParams.get('addressID') ?? '';
        const address = user.address.list?.data?.find((address) => address._id === addressId);
        if (address) return address;
        return user.address.list.data[0];
    };

    const onSubmit = async () => {
        setResponseData((prev) => prev = {
            ...prev,
            isLoaded: true,
        });
        if (user.cart.list.data?.total > 0 && (!user.purchase.paymentMethod.list ||
            (user.purchase.paymentMethod.list === 'CARD' && !user.purchase.creditCardForm.list.creditCardId))) {
            setResponseData({
                isLoaded: false,
                type: 'info',
                message: 'Selecione uma forma de pagamento para realizar a compra',
            });
            return setOpenSnack(true);
        }

        const response = await user.purchase.create();

        if (!response?.error) {
            await user.purchase.orders.load();
            await servicesLoad({
                loadList: ['balance'],
                application: global.app.view.application,
            });
            await user.wallet.walletValue.load();
            user.cart.clear();
            user.purchase.paymentMethod.handleType(undefined);
            return navigate('/checkout/pedido-realizado', {
                state: {
                    paymentType: response?.response.payload.payment?.type,
                    billingLink: response?.response.payload.payment?.informations?.invoiceUrl,
                },
            });
        }
        setResponseData({
            isLoaded: false,
            type: response?.response?.type,
            message: response?.response.message,
        });
        setOpenSnack(true);
    };

    React.useEffect(() => {
        if (!user.address.list.isLoaded) {
            user.address.load();
        }
        if (!user.cart.list.isLoaded) {
            user.cart.load({
                cartType: applicationType,
            });
        }
        if (!user.wallet.walletValue.list.isLoaded) {
            user.wallet.walletValue.load();
        }
    }, []);

    React.useEffect(() => {
        if (user.cart.list.isLoaded) {
            checkItemDiscountItems(user.cart.list.data);
            if (user.cart.list.data?.total === 0) {
                user.purchase.paymentMethod.handleType('PIX');
            }
        }
    }, [user.cart.list]);

    return (
        <Box sx={{ ...css.box.wrapper }}>
            <HeaderPage
                titleAlign={'default'}
                icon={['far', 'bag-shopping']}
                title={'Checkout'}
                action={!user.cart.list?.isLoaded && (
                    <Box px={2}>
                        <CircularProgress color='inherit' size={18} />
                    </ Box>
                )} />
            <Container sx={{ ...css.container }}>
                {!layout.loading.value && !user.cart.list?.data?.totalProducts && !!user.cart.list?.message && (
                    <Box py={'0.9375rem'}>
                        <MessageBox
                            type={'info'}
                            message={user.cart.list.status === 403 ? 'Faça login para visualizar o checkout' : 'Não foi possível encontrar o carrinho para prosseguir com o checkout'}
                        />
                        {user.cart.list.status === 403 &&
                            <Stack
                                direction='row'
                                spacing={2}
                                justifyContent='center'>
                                <Button
                                    type='submit'
                                    variant='contained'
                                    size='large'
                                    onClick={() => navigate('/auth/login')}>
                                    Fazer login
                                </Button>
                            </Stack>
                        }
                    </Box>
                )}
                <Grid sx={{ ...css.grid }} container spacing={mediaQuery.md ? 2 : 1}>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Box sx={{ ...css.box.wrapperSection }}>
                            <Stack spacing={1.5}>
                                <TitleSectionShopping name={'Produtos'} />
                                {!layout.loading.value && user.cart.list.data?.totalProducts > 0 ?
                                    user.cart.list.data.stores.map((store) => (
                                        <Box sx={{ ...css.box.wrapperStore }}>
                                            <Box sx={{ ...css.box.storeHeader }}>
                                                <AvatarGroup
                                                    align={'right'}
                                                    size={'tiny'}
                                                    borderWidth='none'
                                                    title={store.store.title}
                                                    src={files.load(store.store.images.avatar, 'server')} />
                                            </Box>
                                            <Stack divider={<Box sx={{ ...css.box.divider }}></Box>}>
                                                {store.items.map((item, i) => (
                                                    <Box key={`${item.ad._id}-${i}`} p={1}>
                                                        <Link
                                                            style={{ textDecoration: 'none' }}
                                                            to={`/produto/${item.ad._id}?variation=${item.variation._id}&option=${item.variationItem._id}`}>

                                                            <ProductInline
                                                                srcImage={files.load(item?.variation?.images[0], 'server')}
                                                                title={`${item.ad.title} ${item.variationItem.sku.includes('CPFPP541') ? ` - R$${money.formatNumberToPriceBRL(item.variationItem?.originalValue ?? 0)}` : ''}`}
                                                                valueType={applicationType}
                                                                discount={item.variationItem.oldValue}
                                                                value={applicationType === 'REDEMPTION' ? item.variationItem.pointValue : item.variationItem.actualValue}
                                                                cashback={applicationType === 'SHOPPING' ? item.cashback : undefined} />
                                                        </Link>
                                                    </Box>
                                                ))}
                                            </Stack>
                                            {/* <ShippingInfo
                                                name={(store.carrierFreight && store.carrierFreight.name) ?? ''}
                                                days={(store.carrierFreight && store.carrierFreight.delivery_time) ?? 0}
                                            /> */}
                                        </Box>)) :
                                    <Skeleton variant='rectangular' height={!mediaQuery.sm ? 170 : 'calc(100vh - 312px)'} />
                                }
                            </Stack>
                            {/* {mediaQuery.md &&
                                <Box sx={{ ...css.box.divider }}></Box>
                            } */}
                        </Box>
                        {!mediaQuery.md && <Box sx={{ ...css.box.dividerMobile }}></Box>}
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Box sx={{ ...css.box.dividerSection }}>
                            <Box sx={{ ...css.box.wrapperSection }}>
                                <Stack spacing={1.5}>
                                    <TitleSectionShopping name={'Endereço de entrega'} />
                                    <Box sx={{ ...css.box.address }}>
                                        {!layout.loading.value && user.cart.list.data?.totalProducts > 0 && user.address.list.isLoaded ?
                                            (user.cart.list.data?.totalProducts > 0 && Object.keys(findAddress()).length > 0 ?
                                                <AddressBox
                                                    title={findAddress().zipCode}
                                                    legend={formatAddress({
                                                        ...findAddress(),
                                                    })}
                                                    mode='edit'
                                                    onClick={() => dialogEditAddress.current?.show()}
                                                    addressTag={findAddress().nickname}
                                                /> :
                                                ''
                                            ) :
                                            <Skeleton variant='rectangular' height={!mediaQuery.sm ? 170 : 'calc(100vh - 312px)'} />
                                        }
                                    </Box>
                                </Stack>
                            </Box>
                        </Box>
                        {!mediaQuery.md && <Box sx={{ ...css.box.dividerMobile }}></Box>}
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Box sx={{ ...css.box.dividerSection }}>
                            <Box sx={{ ...css.box.wrapperSection }}>
                                <Stack spacing={1.5}>
                                    <TitleSectionShopping name={'Resumo do pedido'} />
                                    {!layout.loading.value && user.cart.list.isLoaded &&
                                        user.cart.list.data?.stores?.length > 0 ? <>
                                        <Stack spacing={1.5}>
                                            <ExpandedListValues
                                                mainValue={{
                                                    title: `Subtotal (${user.cart.list.data.totalProducts} item)`,
                                                    value: <Typography sx={{ ...css.texts.expandedMainValueChildren }}>
                                                        {user.cart.list.data?.redemption.totalPoints ?? 0} Pontos
                                                    </Typography>,
                                                }} />
                                            {user.cart.list.data.totalCashback > 0 &&
                                                <>
                                                    <Box sx={{ ...css.box.dividerResume }}></Box>
                                                    <ExpandedListValues mainValue={{
                                                        title: 'Cashback',
                                                        value: <Typography sx={{ ...css.texts.expandedMainValueChildren }}>
                                                        {user.cart.list.data?.totalCashback ?? 0} Pontos
                                                    </Typography>,
                                                    }} />
                                                </>
                                            }
                                            <Box sx={{ ...css.box.dividerResume }}></Box>
                                            <ExpandedListValues
                                                mainValue={{
                                                    title: 'Taxas',
                                                    value:
                                                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                                            <Typography sx={{ ...css.texts.expandedMainValueChildren }}>
                                                                {((user.cart.list.data?.rates?.administrative ?? 0) + (user.cart.list.data?.rates?.handling ?? 0))} Pontos
                                                            </Typography>
                                                        </Stack>,

                                                }}
                                                expandedChildren={
                                                    <Stack spacing={1} ml={'1.25rem'}>
                                                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                            <Typography sx={{ ...css.texts.expandedValueText }}> Administrativa
                                                            </Typography>
                                                            <Typography sx={{ ...css.texts.expandedValueChildren }}>
                                                                {user.cart.list.data.rates?.administrative ?? 0} Pontos
                                                            </Typography>
                                                        </Stack>
                                                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                            <Typography sx={{ ...css.texts.expandedValueText }}> Manuseio
                                                            </Typography>
                                                            <Typography sx={{ ...css.texts.expandedValueChildren }}>
                                                                {user.cart.list.data.rates?.handling ?? 0} Pontos
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                }
                                            ></ExpandedListValues>
                                            <ExpandedListValues
                                                mainValue={{
                                                    title: 'Valor do frete',
                                                    value:
                                                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                                            <Typography sx={{ ...css.texts.expandedMainValueChildren }}>
                                                                {user.cart.list.data?.redemption.pointsShippingValue ?? 0} Pontos
                                                            </Typography>
                                                            <Typography sx={{ ...css.texts.expandedMainValueChildren }}>
                                                                /
                                                            </Typography>
                                                            <Typography sx={{ ...css.texts.expandedMainValueChildren }}>
                                                                R$ {money.formatNumberToPriceBRL(user.cart.list.data.totalShipping)}
                                                            </Typography>
                                                        </Stack>,
                                                }}
                                                expandedChildren={
                                                    <Stack spacing={1} ml={'1.25rem'}>
                                                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                            <Typography sx={{ ...css.texts.expandedValueText }}> Em pontos
                                                            </Typography>
                                                            <Typography sx={{ ...css.texts.expandedValueChildren }}>
                                                                {user.cart.list.data?.redemption.pointsShippingValue ?? 0} Pontos
                                                            </Typography>
                                                        </Stack>
                                                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                            <Typography sx={{ ...css.texts.expandedValueText }}> Em reais
                                                            </Typography>
                                                            <Typography sx={{ ...css.texts.expandedValueChildren }}>
                                                                R$ {money.formatNumberToPriceBRL(user.cart.list.data.totalShipping)}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                } />

                                                {itemsDiscounts.length > 0 && (
                                                    <ExpandedListValues
                                                        mainValue={{
                                                            title: 'Total de descontos',
                                                            value:
                                                                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                                                    <Typography sx={{ ...css.texts.expandedMainValueChildren }}>
                                                                        - {user.cart.list.data?.discount?.itemDiscountValue ?? 0} Pontos
                                                                    </Typography>
                                                                </Stack>,
                                                        }}
                                                        expandedChildren={
                                                            <Stack spacing={1} ml={'1.25rem'}>
                                                                {itemsDiscounts.map((item, i) => (
                                                                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                                        <Typography sx={{ ...css.texts.expandedValueText }}> {item.ad.title}
                                                                        </Typography>
                                                                        <Typography sx={{ ...css.texts.expandedValueChildren }}>
                                                                            - {(item.discount.itemDiscountValue ?? 0)} Pontos
                                                                            {item.itemDiscount?.endDate && (<p>Desconto válido até {formatShortDate(item.itemDiscount.endDate)}</p>)}
                                                                        </Typography>
                                                                    </Stack>
                                                                ))}
                                                            </Stack>
                                                        } />
                                                )}
                                            <Box sx={{ ...css.box.dividerResume }}></Box>
                                            {user.wallet.walletValue.list.isLoaded && user.wallet.walletValue.list.data.money > 0 &&
                                                <>
                                                    <Wallet />
                                                    <Box sx={{ ...css.box.dividerResume }}></Box>
                                                </>
                                            }
                                            <Coupon />
                                            <Box sx={{ ...css.box.divider }}></Box>
                                            <TitleSectionShopping name={'Total'} />
                                            <ExpandedListValues
                                                mainValue={{
                                                    title: 'A pagar',
                                                    value:
                                                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                                            <Typography sx={{ ...css.texts.expandedMainValueChildren }}>
                                                                {user.cart.list.data.redemption.totalPoints} Pontos
                                                            </Typography>
                                                            <Typography sx={{ ...css.texts.expandedMainValueChildren }}>
                                                                /
                                                            </Typography>
                                                            <Typography sx={{ ...css.texts.expandedMainValueChildren }}>
                                                                R$ {money.formatNumberToPriceBRL(user.cart.list.data?.total)}
                                                            </Typography>
                                                        </Stack>,
                                                }}
                                                expandedChildren={
                                                    <Stack spacing={1} ml={'1.25rem'}>
                                                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                            <Typography sx={{ ...css.texts.expandedValueText }}> Em pontos
                                                            </Typography>
                                                            <Typography sx={{ ...css.texts.expandedValueChildren }}>
                                                                {user.cart.list.data.redemption.totalPoints ?? 0} Pontos
                                                            </Typography>
                                                        </Stack>
                                                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                            <Typography sx={{ ...css.texts.expandedValueText }}> Em reais
                                                            </Typography>
                                                            <Typography sx={{ ...css.texts.expandedValueChildren }}>
                                                                R$ {money.formatNumberToPriceBRL(user.cart.list.data?.total)}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                } />
                                        </Stack>
                                        <Box sx={{ ...css.box.divider, mt: '1rem !important' }}></Box>
                                        <Stack mt={3} spacing={2}>
                                            <Stack justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
                                                <TitleSectionShopping name={'Pagamento'} />
                                                {user.purchase.paymentMethod.list && user.cart.list.data?.total > 0 &&
                                                    <Typography sx={{ ...css.texts.yourBalance }}>
                                                        Seu saldo: <span>{user.info.data.availableBalance} Pontos</span>
                                                    </Typography>
                                                }
                                            </Stack>
                                            {user.purchase.paymentMethod.list && user.cart.list.data?.total > 0 &&
                                                <Stack sx={{ ...css.stack.paymentOptions }} spacing={1}>
                                                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                                        <Typography sx={{ ...css.texts.paymentMethodType }}>
                                                            Pontos + {paymentMethodLabel[user.purchase.paymentMethod.list].title}
                                                        </Typography>
                                                        <I fontSize={16} icon={paymentMethodLabel[user.purchase.paymentMethod.list].icon as IconProp} />
                                                    </Stack>
                                                    {(user.purchase.paymentMethod.list !== 'CARD') &&
                                                        <Typography fontSize={14} variant='caption'>
                                                            {paymentMethodLabel[user.purchase.paymentMethod.list].text}
                                                        </Typography>
                                                    }
                                                </Stack>
                                            }
                                            <Stack spacing={1}>
                                                {user.cart.list.data?.total > 0 ?
                                                    (!user.purchase.paymentMethod.list &&
                                                        <>
                                                            <RadioPoints disabled valuePoints={user.info.data.availableBalance} />
                                                            <RadioPoints valuePoints={user.info.data.availableBalance} />
                                                            <Stack sx={{ ...css.stack.paymentOptions }} direction={'row'} spacing={1}>
                                                                {paymentMethods.map(({ _id, icon, title, type }, i) => (
                                                                    <PaymentFormButton
                                                                        onClick={() => user.purchase.paymentMethod.handleType(type as PaymentMethodType)}
                                                                        icon={icon}
                                                                        title={title}
                                                                        key={_id} />
                                                                ))}
                                                            </Stack>
                                                        </>) :
                                                    <RadioPoints onlyPoints valuePoints={user.info.data.availableBalance} />
                                                }
                                            </Stack>
                                            {user.purchase.paymentMethod.list === 'CARD' &&
                                                <Card />
                                            }
                                        </Stack>
                                        {(!!user.purchase.paymentMethod.list || user.cart.list.data?.total === 0) &&
                                            <Stack sx={{ ...css.stack.paymentOptions }} spacing={1}>
                                                <Button
                                                    fullWidth
                                                    disabled={
                                                        responseData.isLoaded ||
                                                        (user.cart.list.data.notAvailableToCheckout)
                                                    }
                                                    onClick={(user.cart.list.data.notAvailableToCheckout &&
                                                        user.cart.list.data.notAvailableToCheckout) ?
                                                        () => { } :
                                                        () => {
                                                            onSubmit();
                                                        }
                                                    }
                                                    variant='contained'
                                                >
                                                    Comprar {responseData.isLoaded &&
                                                        <CircularProgress
                                                            color="inherit"
                                                            size={14}
                                                            sx={{ ml: 1 }}
                                                        />}
                                                </Button>
                                                {!!user.purchase.paymentMethod.list && user.cart.list.data?.total > 0 &&
                                                    <Button
                                                        onClick={() => user.purchase.paymentMethod.handleType(undefined)}
                                                        fullWidth
                                                        variant='outlined'>
                                                        Alterar forma de pagamento
                                                    </Button>
                                                }
                                            </Stack>
                                        }
                                    </> : <Skeleton variant='rectangular' height={!mediaQuery.sm ? 170 : 'calc(100vh - 312px)'} />
                                    }
                                </Stack>
                            </Box>
                            {/* {!mediaQuery.md && <Box sx={{ ...css.box.dividerMobile }}></Box>} */}
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Template.MuiDialog
                maxWidth='sm'
                fullScreen={!mediaQuery.md}
                title='Editar endereço de entrega'
                ref={dialogEditAddress}>
                <HeaderPage
                    titleAlign={'center'}
                    noPadding
                    icon={['far', 'chevron-left']}
                    onClick={() => dialogEditAddress.current?.hide()}
                    title={'Editar endereço de entrega'} />
                <Box p={2}>
                    <Template.Forms.Address
                        toCheckout
                        externalAction={() => {
                            user.address.load();
                            dialogEditAddress.current?.hide();
                        }}
                        addressID={searchParams.get('addressID') ?? user.address.list?.data[0]?._id} />
                </Box>
            </Template.MuiDialog>
            <Feedback.MuiSnackbar
                open={openSnack}
                severity={responseData.type}
                onClose={() => setOpenSnack(false)}
                message={responseData.message}
            />
        </Box>
    );
};

export { Checkout };
