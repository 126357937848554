/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import { AlertColor, Box, CircularProgress, IconButton, Skeleton, Slide, Stack, Typography, useTheme, Zoom } from '@mui/material';
import { sx } from './sx';
import { ButtonIcon, Price } from '../../molecule';
import { excerpt, files, money, rem, styles } from '@utils';
// import { ProductProps } from '@interfaces';
import { Image } from '../../atoms/image';
import { useUser } from '@hooks/user';
import { Feedback } from '@components';
import { LinkStyled } from './styles';
import { useGlobal } from '@hooks/global';
import { AdDataProps } from '@interfaces/Services';
import { FontAwesomeIconProps, FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { ResponseAsyncProps } from '@interfaces/ClientServicesApi';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { cart } from '../../../../api/services/user';

interface ComponentProductProps {
    product: AdDataProps;
    favorite?: {
        isFavorite?: boolean;
    }
    cart: {
        isInCart?: boolean;
    }
    actions?: {
        handleFavorite: () => Promise<ResponseAsyncProps | undefined>;
        handleCart: () => Promise<ResponseAsyncProps | undefined>;
    }
};

type FavoriteIconStatesType = 'add' | 'loading' | 'success';

interface ProductActionType {
    cart: {
        state: FavoriteIconStatesType;
    };
    favorite: {
        state: FavoriteIconStatesType;
    };
}

const Product: React.FC<ComponentProductProps> = ({ product, favorite, cart, actions }: ComponentProductProps) => {
    const { css } = sx();
    const { typography, palette } = useTheme();
    const mediaQuery = styles.mediaQueryCSS();
    const global = useGlobal();
    // const { favorites, cart } = useUser();

    const applicationType = global.app.view.application;
    const [open, setOpen] = React.useState(false);
    const [responseData, setResponseData] = React.useState({
        type: 'success' as AlertColor,
        message: '',
    });

    const [productAction, setProductAction] = React.useState<ProductActionType>({
        cart: {
            state: cart?.isInCart ? 'success' : 'add',
        },
        favorite: {
            state: favorite?.isFavorite ? 'success' : 'add',
        },
    });

    const handleProductActionState = (
        type: 'favorite' | 'cart',
        action: FavoriteIconStatesType,
    ) => {
        setProductAction((prev) => prev = {
            ...prev,
            [type]: { state: action },
        });
    };

    const handleClick = async (type: 'favorite' | 'cart') => {
        if (type === 'favorite') {
            handleProductActionState('favorite', 'loading');
        }
        if (type === 'cart') {
            handleProductActionState('cart', 'loading');
        };

        let res: ResponseAsyncProps | undefined;
        try {
            if (type === 'favorite' && actions?.handleFavorite) {
                const response = await actions?.handleFavorite();
                res = response;
            }
            if (type === 'cart' && actions?.handleCart) {
                const response = await actions?.handleCart();
                res = response;
            }
            if (res?.error) {
                setResponseData({
                    type: 'warning',
                    message: res?.response.message,
                });
                // handleProductActionState('favorite', 'add');
                // handleProductActionState('cart', 'add');
                setOpen(true);
                return;
            }

            // if (type === 'favorite') {
            //     handleProductActionState(
            //         'favorite',
            //         res?.response?.message.includes('removido') ?
            //             'add' :
            //             'success',
            //     );
            // }
            // if (type === 'cart') {
            //     handleProductActionState(
            //         'cart',
            //         res?.response?.message.includes('adicionado') ?
            //             'success' :
            //             'add',
            //     );
            // }
            setResponseData({
                type: !!res?.response?.type ? res?.response?.type : 'success',
                message: res?.response.message,
            });
            setOpen(true);
            return;
        } catch (error) {
            handleProductActionState('favorite', 'add');
            handleProductActionState('cart', 'add');
        }
    };

    const productActionIcon = (fwIcon: IconProp) => {
        const icon = {
            color: palette.primaryColor,
            fontSize: 16,
        } as FontAwesomeIconProps;
        return (
            <I {...icon} icon={fwIcon} />
        );
    };

    const handleProcutActionButton = (type: FavoriteIconStatesType, iconType: {
        add: IconProp;
        success: IconProp;
    }) => {
        let renderFavoriteChildren: React.ReactNode;
        switch (type) {
            case 'loading':
                renderFavoriteChildren = (
                    <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                        <CircularProgress
                            size={16}
                            sx={{ color: palette.darkGray }}
                        />
                    </Slide>);
                break;
            case 'add':
                renderFavoriteChildren = productActionIcon(iconType.add);
                break;
            case 'success':
                renderFavoriteChildren = productActionIcon(iconType.success); // <I {...icon} icon={['fas', 'heart']} />;
                break;
            default:
                renderFavoriteChildren = productActionIcon(iconType.add);
                break;
        }

        return renderFavoriteChildren;
    };

    const handleClose = () => setOpen(false);

    const excerptMaxWords = !mediaQuery.sm ? 8 : 12;

    React.useEffect(() => {
        setProductAction({
            cart: {
                state: cart?.isInCart ? 'success' : 'add',
            },
            favorite: {
                state: favorite?.isFavorite ? 'success' : 'add',
            },
        });
    }, [cart, favorite]);

    return (
        <Box height={'100%'} position={'relative'}>
            <LinkStyled to={`/produto/${product._id}?variation=${product.variations._id}&option=${product.variations.items[0]._id}`}>
            </LinkStyled>
            <Box sx={css.box.wrapper}>
                <Box sx={css.box.image.wrapper}>
                    <Image
                        src={files.load(product.variations.images[0], 'server')}
                        alt={product.title}
                        objectFit='contain'
                        maxHeight={!mediaQuery.sm ? 112 : 192}
                    />
                </Box>

                <Box sx={css.box.content.wrapper}>
                    <Typography
                        component='h2'
                        fontSize={rem(!mediaQuery.sm ? 13 : 14)}
                        color='dark'
                        fontWeight={typography.fontWeightSemiBold}
                        mb={rem(10)}
                        sx={css.box.content.title}
                    >
                        {excerpt({
                            text: product.variations.items[0].sku.includes('CPFPP541') ?
                                `${product.title} - R$${money.formatNumberToPriceBRL(product.variations.items[0].originalValue ?? 0)}` :
                                product.title,
                            excerptMaxTextLength: excerptMaxWords,
                            ellipsis: true,
                        })}
                    </Typography>
                </Box>
                <Box sx={css.box.content.footer.wrapper}>
                    {applicationType.isLoaded ? (
                        <Price
                            type={applicationType.type}
                            value={applicationType.type === 'REDEMPTION' ?
                                Number(product.variations.items[0].pointValue) :
                                product.variations.items[0].actualValue}
                            discount={product.oldValue}
                            size={mediaQuery.sm ? 'medium' : 'small'}
                        />
                    ) : <Skeleton variant="rectangular" width={96} height={22} />}
                    {applicationType.isLoaded && (product.variations.items[0].cashbackValue &&
                        product.variations.items[0].cashbackValue > 0) ?
                        (
                            <Box mt={rem(4)}>
                                <Typography
                                    component='h2'
                                    fontSize={rem(10)}
                                    color='dark'
                                    fontWeight={typography.fontWeightMedium}
                                    sx={{
                                        '& > span': {
                                            color: palette.mediumVioletRed,
                                            fontWeight: typography.fontWeightSemiBold,
                                        },
                                    }}
                                >
                                    Ganhe <span>{product.variations.items[0].cashbackValue} pontos</span> com esta compra
                                </Typography>
                            </Box>
                        ) : null}
                    <Stack py={1} pr={1} justifyContent='end' direction='row' spacing={1}>

                        <IconButton
                            onClick={() => handleClick('favorite')}
                            disabled={productAction.favorite.state === 'loading'}
                        >
                            {handleProcutActionButton(productAction.favorite.state, {
                                add: ['far', 'heart'],
                                success: ['fas', 'heart'],
                            })}
                        </IconButton>
                        <IconButton
                            onClick={() => handleClick('cart')}
                            disabled={productAction.cart.state === 'loading'}
                        >
                            {handleProcutActionButton(productAction.cart.state, {
                                add: ['far', 'cart-shopping'],
                                success: ['fas', 'cart-shopping'],
                            })}
                        </IconButton>
                    </Stack>
                </Box>
                <Feedback.MuiSnackbar
                    open={open}
                    severity={responseData.type}
                    onClose={handleClose}
                    message={responseData.message}
                />
            </Box>
        </Box>
    );
};

export { Product };
