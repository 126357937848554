/* eslint-disable max-len */
import axios, { AxiosError, AxiosResponse } from 'axios';
import { ErrorDataProps, ErrorsDataPayloadProps } from '@interfaces/ClientServicesApi';
import config from '../configs/config.json';
import * as auth from './services/auth';
import * as user from './services/user';
import * as ads from './services/ads';
import * as banners from './services/banners';
import * as purchase from './services/purchase';
import * as recharge from './services/recharge';
import Cookies from 'universal-cookie';

export const client = () => {
    const services = {
        auth,
        user,
        ads,
        banners,
        purchase,
        recharge,
    };
    const cookies = new Cookies(null, { path: '/' });
    const instance = axios.create({
        baseURL: config.apis.url, // URL_API
        timeout: 120000,
        withCredentials: true,
        headers: {
            'Accept-Language': 'pt-br',
            'Client-Domain': !!config.domainSplit ? window.location.host.split(config.domainSplit)[0] : 'commarket', // Aqui vai entrar o subdominio da empresa
        },
    });
    const unauthorizedMessages = {
        status401: 'You are not logged in',
        status403: 'Expired token',
    };

    // Doc: https://www.thedutchlab.com/en/insights/using-axios-interceptors-for-refreshing-your-api-token
    // Request interceptor for API calls

    // * Caso não tenha tolken não chama a api e envia para o login
    instance.interceptors.request.use(
        async (config) => {
            if ((config.url?.includes('/user')) && config.method !== 'put') {
                return config;
            }
            const sessionToken = cookies.get('session');
            config.headers['Authorization'] = `Bearer ${sessionToken}`;
            return config;
        },
        (error) => {
            Promise.reject(error);
        });

    // Response interceptor for API calls
    instance.interceptors.response.use((response) => {
        return response;
    }, async (error) => {
        switch (error.response.status) {
        case 401:
            if (error.response.data.message === unauthorizedMessages.status401) {
                // window.location.href = '/auth/login';
            }
            break;
        case 403:
            if (error.response.data.message === unauthorizedMessages.status403) {
                // if (error.response.config.url !== '/user') { }
                // window.location.href = '/auth/login';
            }
            break;

        default:
            break;
        }
        // if (error.response.status === 403) {
        //     if (error.response.data.message === unauthorizedMessages.status403) {
        //         console.log('RESPONSE: ', error.response.data.message);
        //         window.location.href = '/auth/login';
        //     }
        // }
        return Promise.reject(error);
    });

    const handleResponse = (response: AxiosResponse) => {
        const res = {
            ...response.data,
            status: response.status,
        };
        return {
            error: false,
            response: res,
        };
    };

    const handleErrors = (error: any) => {
        if (error instanceof AxiosError) {
            const response = {
                ...error.response?.data as ErrorDataProps | ErrorsDataPayloadProps,
                status: error.response?.status,
            };

            return {
                error: true,
                response,
            };
        }
    };
    return {
        services,
        instance,
        handleErrors,
        handleResponse,
    };
};
