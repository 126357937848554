/* eslint-disable max-len */
import * as React from 'react';
import { useAutocomplete } from '@mui/base/useAutocomplete';
import { styled } from '@mui/system';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { Box, IconButton, useTheme } from '@mui/material';
import { useUser } from '@hooks/user';
import { money } from '@utils';

export const Label = styled('label')(({ theme }) => ({
    display: 'block',
    fontSize: 12,
    color: theme.palette.grey[600],
    fontWeight: '600',
    fontFamily: 'Open Sans, sans-serif',
    marginBottom: 2,
}));

const Input = styled('input')(({ theme }) => ({
    width: '100%',
    height: '56px',
    border: 'none',
    fontSize: 14,
    borderBottom: '1px solid ' + theme.palette.grey[300],
    backgroundColor: '#fff',
    color: '#000',
    outlineColor: 'transparent',
    ...theme.applyStyles('dark', {
        backgroundColor: '#000',
        color: '#fff',
    }),
}));

const Listbox = styled('ul')(({ theme }) => ({
    'width': 200,
    'margin': 0,
    'padding': 0,
    'zIndex': 1,
    'position': 'absolute',
    'listStyle': 'none',
    'backgroundColor': '#fff',
    'overflow': 'auto',
    'maxHeight': 200,
    'border': '1px solid rgba(0,0,0,.25)',
    '& li > div': {
        padding: '8px 12px',
        fontSize: 14,
        fontWeight: 'normal',
    },
    '& li.Mui-focused': {
        backgroundColor: theme.palette.primaryColor,
        color: 'white',
        cursor: 'pointer',
    },
    '& li:active': {
        backgroundColor: theme.palette.primaryColor,
        color: 'white',
    },
    ...theme.applyStyles('dark', {
        backgroundColor: '#000',
    }),
}));

export default function UseAutocomplete() {
    const { palette } = useTheme();
    const { recharge } = useUser();
    const {
        getRootProps,
        getInputLabelProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
        getClearProps,
    } = useAutocomplete({
        id: 'recharge-ranges-autocomplete',
        options: recharge.values.list.data,
        getOptionLabel: (option) => {
            // if (!!recharge.values.selectedValue.sku) {
            //     return `R$${money.formatNumberToPriceBRL(option.value)} em créditos`;
            // }
            // return '';
            return `R$${money.formatNumberToPriceBRL(option.value)} em créditos`;
        },
    });

    React.useEffect(() => {
        if (!recharge.values.selectedValue.sku) {
            getClearProps();
        }
    }, [recharge.values.selectedValue]);

    // console.log('!!recharge.values.selectedValue.sku', !!recharge.values.selectedValue.sku);

    return (
        <Box width="100%">
            <Box {...getRootProps()}>
                <Label {...getInputLabelProps()}>Quantos créditos voce deseja?</Label>
                <Box sx={{
                    position: 'relative',
                    height: '56px',
                }}>
                    <Input
                        {...getInputProps()}
                        readOnly
                        placeholder={
                            getInputProps().value ?
                                `${getInputProps().value}` :
                                'Selecione'
                        }
                    />
                    <IconButton aria-label="delete" disabled color="primary" sx={{
                        position: 'absolute',
                        right: '0',
                        top: '0',
                        bottom: '0',
                        margin: 'auto',
                    }}>
                        <I icon={['fas', 'caret-down']}
                            color={palette.dark}
                            fontSize={14} />
                    </IconButton>
                </Box>
            </Box>
            {groupedOptions.length > 0 ? (
                <Listbox {...getListboxProps()}>
                    {(groupedOptions as typeof recharge.values.list.data).map((option, index) => {
                        const { key, ...optionProps } = getOptionProps({ option, index });
                        return (
                            <li key={key} {...optionProps}>
                                <div onClick={() => recharge.purchase.handleObj('value', option.sku)}>
                                    R${money.formatNumberToPriceBRL(option.value)} em créditos
                                </div>
                            </li>
                        );
                    })}
                </Listbox>
            ) : null}
        </Box>
    );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
// const ranges = [
//     { title: 'R$50 em créditos', value: 50 },
//     { title: 'R$100 em créditos', value: 100 },
//     { title: 'R$200 em créditos', value: 200 },
//     { title: 'R$300 em créditos', value: 300 },
//     { title: 'R$400 em créditos', value: 400 },
//     { title: 'R$500 em créditos', value: 500 },
//     { title: 'R$600 em créditos', value: 600 },
// ];
