import { MenuItem } from '@components';
import { useLayout } from '@hooks/layout';
import Box from '@mui/material/Box';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const MenuServices: React.FC = () => {
    const navigate = useNavigate();

    const { layout } = useLayout();

    React.useEffect(() => {
        layout.mobile.header.headerSettings({
            title: 'Serviços',
            onBackAction: () => navigate(-1),
        });
    }, []);

    return (
        <Box>
            <MenuItem
                size={'small'}
                title={'Recarga de Cartão'}
                onClick={() => navigate('/servicos/recargas')} />
        </Box>
    );
};

export { MenuServices };
