/* eslint-disable max-len */
import React from 'react';
import { useUser } from '@hooks/user';
import { AlertColor, Box, Button, Stack, Typography } from '@mui/material';
import { Feedback } from '@components';
import { SendCodeType } from '../../send-code-type';


interface RechargeStep1Props {
    onSubmitButton: () => void
    handlePrevious: () => void
    visible: boolean
}

export const RechargeStep1 = ({ onSubmitButton, handlePrevious, visible }: RechargeStep1Props) => {
    const { info, recharge } = useUser();

    const [openSnack, setOpenSnack] = React.useState(false);
    const [responseData, setResponseData] = React.useState({
        isLoaded: true,
        currentType: 'EMAIL' as 'EMAIL' | 'SMS',
        type: 'success' as AlertColor,
        message: '',
    });

    const onSubmit = async (type: 'EMAIL' | 'SMS') => {
        setResponseData((prev) => prev = {
            ...prev,
            currentType: type,
            isLoaded: false,
        });
        recharge.purchase.handleObj('codeType', type);
        const response = await recharge.sendCodeEmail({
            sendTo: type,
        });

        if (!response?.error) {
            onSubmitButton();
            setResponseData((prev) => prev = {
                ...prev,
                isLoaded: true,
            });
        } else {
            setResponseData({
                isLoaded: true,
                currentType: type,
                type: response?.response?.type,
                message: response?.response.message,
            });
            setOpenSnack(true);
        }
        // recharge.purchase.handleObj('code', data.code);
    };

    return (
        <Box p={4} display={visible ? 'block' : 'none'}>
            <Box>
                <Typography
                    color="dark"
                    variant="h6"
                    textAlign="left"
                    fontWeight="bold"
                    gutterBottom>
                    Código de confirmação
                </Typography>
                <Typography
                    variant="subtitle2"
                    textAlign="left"
                    color="dark">
                    Receba o código de confirmação por
                </Typography>
            </Box>
            <Box pt={4}>
                <Stack spacing={1} maxWidth={380}>
                    <SendCodeType
                        isLoading={!responseData.isLoaded && responseData.currentType === 'EMAIL'}
                        disabled={!responseData.isLoaded}
                        type={'email'}
                        info={`**********${info.data.email.slice(10)}`}
                        onClick={() => onSubmit('EMAIL')} />
                    {!!info.data?.phone?.number &&
                        <SendCodeType
                            isLoading={!responseData.isLoaded && responseData.currentType === 'SMS'}
                            disabled={!responseData.isLoaded}
                            type={'sms'}
                            info={`(**) ******${info.data?.phone?.number.replace(' ', '').slice(5)}`}
                            onClick={() => onSubmit('SMS')} />
                    }
                </Stack>
                <Stack direction="row" justifyContent="flex-end" pt={4}>
                    <Button
                        type='button'
                        variant='text'
                        size='large'
                        onClick={handlePrevious}>
                        Cancelar
                    </Button>
                </Stack>
            </Box>
            <Feedback.MuiSnackbar
                open={openSnack}
                severity={responseData.type}
                onClose={() => setOpenSnack(false)}
                message={responseData.message}
            />
        </Box>
    );
};
