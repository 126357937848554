import { useState } from 'react';
import { client } from '@api';
import { DEFAULTS } from './DEFAULTS';
import {
    PurchaseCreditCardProps,
    RechargeValuesProps,
    SendRechargeCodeEmailProps,
} from '@interfaces/Services';
import { RechargePurchaseKeysType } from '@types';

export const userRecharge = () => {
    const { services } = client();

    const [rechargeValues, setRechargeValues] = useState(DEFAULTS.USER.RECHARGE.VALUES);
    const [selectedValue, setSelectedValue] = useState({} as RechargeValuesProps);
    const [purchaseObj, setPurchaseObj] = useState<PurchaseCreditCardProps>({
        codeType: 'EMAIL',
        code: '',
        codeCard: '',
        value: '',
        birthDate: '',
    });

    const loadValues = async () => {
        const response = await services.recharge.loadRechargeValues();
        setRechargeValues((prev) => prev = {
            message: response?.response?.message,
            isLoaded: true,
            error: !!response?.error,
            status: response?.response?.status,
            data: response?.response?.payload ?
                response?.response.payload : [],
        });
        return response;
    };

    const sendCodeEmail = async (data: SendRechargeCodeEmailProps) => {
        const response = await services.recharge.sendRechargeCodeEmail(data);
        return response;
    };

    const sendRechargePurchase = async () => {
        const response = await services.recharge.purchaseCreditCard(purchaseObj);
        return response;
    };

    const handlePurchaseObj = (property: RechargePurchaseKeysType, value: string) => {
        if (property === 'value') {
            setSelectedValue(
                rechargeValues.data?.find((item) =>
                    item.sku === value) as RechargeValuesProps);
        }
        setPurchaseObj((prev) => prev = {
            ...prev,
            [property]: value,
        });
    };

    const clearData = () => {
        setPurchaseObj({} as PurchaseCreditCardProps);
        setSelectedValue({} as RechargeValuesProps);
    };


    return {
        values: {
            load: loadValues,
            list: rechargeValues,
            selectedValue: selectedValue,
        },
        clearData,
        sendCodeEmail,
        purchase: {
            list: purchaseObj,
            send: sendRechargePurchase,
            handleObj: handlePurchaseObj,
        },
    };
};
