/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    AlertColor,
    Box,
    Button,
    CircularProgress,
    FormHelperText,
    Table,
    TableBody,
    TextField,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { AvatarGroup, Feedback, HeaderPage, TableList } from '@components';
import { sx } from './sx';
import { useUser } from '@hooks/user';
import { VisuallyHiddenInput } from './styles';
import { files, phone } from '@utils';
import { useGlobal } from '@hooks/global';
import { SubmitHandler, useForm } from 'react-hook-form';

import $ from 'jquery';
import 'jquery-mask-plugin';

export interface UpdateUserProfileForm {
    name: string;
    phone: string
}

const EditProfile: React.FC = () => {
    const { css } = sx();
    const navigate = useNavigate();

    const user = useUser();
    const global = useGlobal();
    const [isLoading, setIsLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [responseData, setResponseData] = React.useState({
        type: 'success' as AlertColor,
        message: '',
    });
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<UpdateUserProfileForm>({
        mode: 'onSubmit',
    });
    const handleClose = () => setOpen(false);

    const uploadAvatar = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        const avatarFormData = new FormData();
        const response = await files.handle(event);
        if (!response.error) {
            avatarFormData.append('image', response.files[0].file);
            const upload = await user.upload.images({
                type: 'AVATAR',
                data: avatarFormData,
            });

            if (!upload?.error) {
                const cookieSession = await global.app.cookies.get('session');
                await user.load({ jwt: cookieSession.data.jwt });
            }
            setIsLoading(false);
        }
    };

    const onSubmit: SubmitHandler<UpdateUserProfileForm> = async (data) => {
        setIsLoading(true);
        const cookieSession = await global.app.cookies.get('session');
        if (!phone.validatePhoneNumber(data.phone)) {
            setError('phone', { message: 'Número invalido' });
            setIsLoading(false);
            return;
        }
        const response = await user.update({
            data: {
                name: data.name,
                phone: '+55 ' + data.phone,
            },
            jwt: cookieSession.data.jwt,
        });

        if (response?.error) {
            setResponseData((prev) => prev = {
                message: response?.response.message,
                type: 'error',
            });
            setOpen(true);
            setIsLoading(false);
            return;
        }
        await user.load({ jwt: cookieSession.data.jwt });
        setResponseData((prev) => prev = {
            message: response?.response.message,
            type: response?.response.type,
        });
        setOpen(true);
        setTimeout(() => {
            setIsLoading(false);
            navigate('/minha-conta');
        }, 1500);
    };

    const inputMasks = () => {
        $('.cpf').mask('000.000.000-00');
        $('.cellphone').mask('(00) 00000-0000');
    };

    React.useEffect(() => {
        inputMasks();
    }, []);

    return (
        <Box sx={{ ...css.box.wrapper }}>
            <HeaderPage
                noPadding
                onClick={() => navigate(-1)}
                icon={['far', 'chevron-left']}
                titleAlign={'default'}
                title={'Minha Conta'}
            />
            <Box component='form' onSubmit={handleSubmit(onSubmit)} position="relative">
                {isLoading && (
                    <Box sx={css.overlay}>
                        <CircularProgress color="inherit" size={20} />
                    </Box>
                )}
                <Table>
                    <TableBody>
                        <TableList
                            customWidthTd={146}
                            align={'left'}
                            keyTab={'Foto de perfil'}
                            value={
                                <Box sx={{ ...css.box.avatarEdit }}>
                                    <Box position='relative'>
                                        <AvatarGroup
                                            borderWidth={'none'}
                                            align={'bottom'}
                                            size={'medium'}
                                            src={user.info.data.images ?
                                                files.load(
                                                    user.info.data.images.avatar,
                                                    'server',
                                                ) : ''}
                                        />
                                    </Box>
                                    <Button
                                        component='label'
                                        variant='contained'
                                        size='medium'
                                    >
                                        Alterar
                                        <VisuallyHiddenInput
                                            type="file"
                                            onChange={uploadAvatar}
                                        />
                                        <I
                                            style={{ ...css.icon.camera }}
                                            icon={['fas', 'camera']} />
                                    </Button>
                                </Box>
                            } />
                        <TableList
                            customWidthTd={146}
                            align={'left'}
                            keyTab={'Nome Completo'}
                            value={
                                <TextField
                                    sx={{ ...css.textfield }}
                                    fullWidth
                                    focused={true}
                                    id="name"
                                    helperText=''
                                    variant="outlined"
                                    defaultValue={user.info.data.name}
                                    InputProps={{
                                        readOnly: false,
                                    }}
                                    error={!!errors.name}
                                    {...register('name',
                                        { required: true },
                                    )} />
                            } />
                        <TableList
                            customWidthTd={146}
                            align={'left'}
                            keyTab={'Email'}
                            value={
                                <TextField
                                    sx={{ ...css.textfield }}
                                    fullWidth
                                    focused={true}
                                    id="email"
                                    helperText=''
                                    variant="outlined"
                                    InputProps={{
                                        readOnly: true,
                                        value: user.info.data.email,
                                    }} />
                            } />
                        <TableList
                            customWidthTd={146}
                            align={'left'}
                            keyTab={'CPF'}>
                            <TextField
                                sx={{ ...css.textfield }}
                                fullWidth
                                focused={true}
                                id="cpf"
                                helperText=''
                                variant="outlined"
                                defaultValue={user.info.data.cpf}
                                InputProps={{
                                    readOnly: true,
                                }} />
                        </TableList>
                        <TableList
                            customWidthTd={146}
                            align={'left'}
                            keyTab={'Telefone'}>
                            <Box sx={css.customInput}>
                                <input
                                    type='text'
                                    className={`cellphone ${errors.phone ? 'error' : ''}`}
                                    defaultValue={user.info.isLoaded ?
                                        user.info.data.phone ?
                                            phone.formatNumber({
                                                ddd: user.info.data.phone.ddd,
                                                number: user.info.data.phone.number,
                                            }) :
                                            '' :
                                        ''}
                                    {...register('phone',
                                        { required: true },
                                    )}
                                />
                                {errors.phone &&
                                    <FormHelperText error>
                                        {errors.phone.message}
                                    </FormHelperText>
                                }
                            </Box>
                        </TableList>
                        <TableList
                            customWidthTd={146}
                            align={'left'}
                            keyTab={'Senha'}
                            value={
                                <Box display={'flex'}>
                                    <TextField
                                        sx={{ ...css.textfield }}
                                        fullWidth
                                        focused={true}
                                        id="password"
                                        helperText=''
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                            value: '********',
                                        }} />
                                    <Button
                                        onClick={() =>
                                            navigate('/minha-conta/alterar-senha')}
                                        sx={{ ...css.button.changePass }}
                                        type='button'
                                        variant='text'
                                        size='large'>
                                        Alterar
                                    </Button>
                                </Box>
                            } />
                    </TableBody>
                </Table>
                <Box sx={{ ...css.box.wrapperButton }}>
                    <Box sx={{ ...css.box.buttonSubmit }}>
                        <Button
                            fullWidth
                            type='submit'
                            variant='contained'
                            size='large'>
                            Salvar alterações
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Feedback.MuiSnackbar
                open={open}
                severity={responseData.type}
                onClose={handleClose}
                message={responseData.message}
            />
        </Box>
    );
};

export { EditProfile };
