/* eslint-disable max-len */
import React from 'react';
import { Feedback } from '@components';
import { useUser } from '@hooks/user';
import { AlertColor, Box, Button, Stack, TextField, Typography, useTheme } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';

interface RechargeStep2FormProps {
    code: string
}

interface RechargeStep2Props {
    onSubmitButton: () => void
    handlePrevious: () => void
    visible: boolean
}

const codeTypeText = {
    EMAIL: ' para o email ',
    SMS: ' via SMS para ',
};

export const RechargeStep2 = ({ onSubmitButton, handlePrevious, visible }: RechargeStep2Props) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<RechargeStep2FormProps>({
        mode: 'onSubmit',
    });
    const { palette } = useTheme();
    const { info, recharge } = useUser();

    const [openSnack, setOpenSnack] = React.useState(false);
    const [responseData, setResponseData] = React.useState({
        isLoaded: true,
        type: 'success' as AlertColor,
        message: '',
    });

    const resendCode = async () => {
        setResponseData((prev) => prev = {
            ...prev,
            isLoaded: false,
        });
        const response = await recharge.sendCodeEmail({
            sendTo: recharge.purchase.list.codeType,
        });

        setResponseData({
            isLoaded: true,
            type: response?.response?.type,
            message: response?.response.message,
        });
        setOpenSnack(true);
    };

    const onSubmit: SubmitHandler<RechargeStep2FormProps> = async (data) => {
        recharge.purchase.handleObj('code', data.code);
        onSubmitButton();
    };

    return (
        <Box p={4} display={visible ? 'block' : 'none'}>
            <Box>
                <Typography
                    color="dark"
                    variant="h6"
                    textAlign="left"
                    fontWeight="bold"
                    gutterBottom>
                    Código de confirmação
                </Typography>
                <Typography
                    variant="subtitle2"
                    textAlign="left"
                    color="dark">
                    O código de confirmação foi enviado {codeTypeText[recharge.purchase.list.codeType]}
                    <strong>
                        {recharge.purchase.list.codeType === 'EMAIL' ?
                            `**********${info.data.email.slice(10)}` :
                            `(**) ******${info.data?.phone?.number.replace(' ', '').slice(5)}`}
                    </strong>
                </Typography>
            </Box>
            <Box component="form" pt={4} onSubmit={handleSubmit(onSubmit)}>
                <Box maxWidth={380}>
                    <TextField
                        fullWidth
                        focused
                        label='Digite o código'
                        id="outlined-basic"
                        helperText=''
                        variant="outlined"
                        error={!!errors.code}
                        {...register('code',
                            { required: true },
                        )} />
                </Box>
                <Button
                    onClick={() => resendCode()}
                    disabled={!responseData.isLoaded}
                    sx={{
                        mt: 1,
                        color: palette.secondaryColor,
                        fontSize: '0.8125rem',
                        fontWeight: 'semiBold',
                    }}>
                    Reenviar código
                </Button>
                <Stack direction="row" justifyContent="flex-end" spacing={2} pt={4}>
                    <Button
                        type='button'
                        variant='text'
                        size='large'
                        onClick={handlePrevious}>
                        Voltar
                    </Button>
                    <Button
                        type='submit'
                        variant='contained'
                        size='large'>
                        Fazer Resgate
                    </Button>
                </Stack>
            </Box>
            <Feedback.MuiSnackbar
                open={openSnack}
                severity={responseData.type}
                onClose={() => setOpenSnack(false)}
                message={responseData.message}
            />
        </Box>
    );
};
