/* eslint-disable max-len */
import { Box, Button, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

interface RechargeStep5Props {
    onSubmitButton: () => void
    visible: boolean
}

export const RechargeStep5 = ({ onSubmitButton, visible }: RechargeStep5Props) => {
    const { palette } = useTheme();

    const onSubmit = () => {
        onSubmitButton();
    };

    return (
        <Box p={4} display={visible ? 'block' : 'none'}>
            <Box display="flex" justifyContent="center" mb={2}>
                <Box sx={{
                    height: 64,
                    width: 64,
                    borderRadius: 100,
                    backgroundColor: palette.status.accepted,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <I icon={['fas', 'check']} fontSize={32} color={palette.common.white} />
                </Box>
            </Box>
            <Box mb={3}>
                <Typography
                    color="dark"
                    variant="h6"
                    textAlign="center"
                    fontWeight="bold"
                    gutterBottom>
                    Recarga solicitada
                </Typography>
                <Typography
                    variant="subtitle2"
                    textAlign="center"
                    color="dark">
                    O tempo para o processamento da recarga é de 1 a 8 dias corridos.
                </Typography>
            </Box>
            <Box display="flex" justifyContent="center">
                <Button
                    type='submit'
                    variant='contained'
                    size='large'
                    onClick={onSubmit}>
                    Fechar
                </Button>
            </Box>
        </Box>
    );
};
