/* eslint-disable max-len */
import React from 'react';
import { AlertColor, Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { RechargeListValue } from '../../list-value';
import { money } from '@utils';
import { useUser } from '@hooks/user';
import { ExpandedListValues, Feedback } from '@components';
import { useLoad } from '@hooks/load';
import { useGlobal } from '@hooks/global';
import { sx } from '../../../sx';

interface RechargeStep4Props {
    onSubmitButton: () => void
    handlePrevious: () => void
    visible: boolean
}

export const RechargeStep4 = ({ onSubmitButton, handlePrevious, visible }: RechargeStep4Props) => {
    const { recharge, wallet, purchase } = useUser();
    const { servicesLoad } = useLoad();
    const global = useGlobal();
    const [openSnack, setOpenSnack] = React.useState(false);
    const [responseData, setResponseData] = React.useState({
        isLoaded: true,
        type: 'success' as AlertColor,
        message: '',
    });

    const { css } = sx();

    const onSubmit = async () => {
        setResponseData((prev) => prev = {
            ...prev,
            isLoaded: false,
        });
        const response = await recharge.purchase.send();
        setResponseData((prev) => prev = {
            ...prev,
            type: response?.response?.type,
            message: response?.response.message,
        });

        if (!response?.error) {
            await purchase.orders.load();
            await servicesLoad({
                loadList: ['balance'],
                application: global.app.view.application,
            });
            await wallet.walletValue.load();
            onSubmitButton();
        } else {
            setResponseData((prev) => prev = {
                ...prev,
                isLoaded: true,
            });
            setOpenSnack(true);
        }
    };

    return (
        <Box p={4} display={visible ? 'block' : 'none'}>
            <Box mb={3}>
                <Typography
                    color="dark"
                    variant="h6"
                    textAlign="left"
                    fontWeight="bold"
                    gutterBottom>
                    Revisar informações
                </Typography>
                <Typography
                    variant="subtitle2"
                    textAlign="left"
                    color="dark">
                    Revise as informações abaixo para antes de confirmar o resgate
                </Typography>
            </Box>
            <Box>
                <Box mb={1}>
                    <Typography
                        color="dark"
                        variant="body1"
                        textAlign="left"
                        fontWeight="bold">
                        Resumo do resgate
                    </Typography>
                </Box>
                <RechargeListValue
                    title='Número do cartão'
                    value={recharge.purchase.list.codeCard}
                    divider
                />
                <RechargeListValue
                    title='Data de nascimento'
                    value={recharge.purchase.list?.birthDate?.split('-').reverse().join('/')}
                    divider
                />
                <RechargeListValue
                    title='Crédito'
                    value={!!recharge.values.selectedValue.sku ? 'Presente Perfeito' : '-'}
                    divider
                />
                <RechargeListValue
                    title='Valor em créditos'
                    value={!!recharge.values.selectedValue.sku ? `R$${money.formatNumberToPriceBRL(recharge.values.selectedValue.value)}` : '-'}
                    divider
                />
                <RechargeListValue
                    title='Valor em pontos'
                    value={!!recharge.values.selectedValue.sku ? `${recharge.values.selectedValue.pointValue} Pontos` : '-'}
                    divider
                />
                <Box py={1}>
                    <ExpandedListValues
                        mainValue={{
                            title: 'Taxas',
                            value:
                                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                    <Typography sx={{ ...css.texts.expandedMainValueChildren }}>
                                        {!!recharge.values.selectedValue.sku ? `${recharge.values.selectedValue.fee} Pontos` : '-'}
                                    </Typography>
                                </Stack>,

                        }}
                        expandedChildren={ !!recharge.values.selectedValue.sku ?
                            <Stack spacing={1} ml={'1.25rem'}>
                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography sx={{ ...css.texts.expandedValueText }}> Administrativa</Typography>
                                    <Typography sx={{ ...css.texts.expandedValueChildren }}>
                                        {recharge.values.selectedValue.fee} Pontos
                                    </Typography>
                                </Stack>
                            </Stack> : undefined
                        }
                    ></ExpandedListValues>
                </Box>
                <Box sx={{ ...css.divider }} />
                <RechargeListValue
                    title='Total'
                    boldTitle
                    value={!!recharge.values.selectedValue.sku ? `${recharge.values.selectedValue.pointValue+recharge.values.selectedValue.fee} Pontos` : '-'}
                    divider
                />
                <Stack direction="row" justifyContent="flex-end" spacing={2} pt={4}>
                    <Button
                        type='button'
                        variant='text'
                        size='large'
                        onClick={handlePrevious}>
                        Voltar
                    </Button>
                    <Button
                        type='button'
                        variant='contained'
                        size='large'
                        disabled={!responseData.isLoaded}
                        onClick={onSubmit}>
                        Confirmar Resgate {!responseData.isLoaded &&
                            <CircularProgress
                                color="inherit"
                                size={14}
                                sx={{ ml: 1 }}
                            />}
                    </Button>
                </Stack>
            </Box>
            <Feedback.MuiSnackbar
                open={openSnack}
                severity={responseData.type}
                onClose={() => setOpenSnack(false)}
                message={responseData.message}
            />
        </Box>
    );
};
