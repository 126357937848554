import { useTheme } from '@mui/material';
import { rem } from '@utils';


export const sx = () => {
    const { typography, palette } = useTheme();

    const css = {
        divider: {
            width: '100%',
            height: '0.125rem',
            borderBottom: `1px dashed ${palette.cityLights}`,
        },
        texts: {
            balanceInPoints: {
                fontWeight: typography.fontWeightMedium,
                fontSize: rem(12),
            },
            expandedValueText: {
                fontWeight: typography.fontWeightMedium,
                fontSize: rem(12),
            },
            expandedValueChildren: {
                fontWeight: typography.fontWeightBold,
                fontSize: rem(12),
            },
            expandedMainValueChildren: {
                fontWeight: typography.fontWeightBold,
                fontSize: rem(14),
            },
        },
    };

    return { css };
};
